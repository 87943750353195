import { useMediaQuery } from 'react-responsive'
import {useState, useEffect} from 'react'

import Btn from 'components/Btn/Btn';
import Section from 'components/Section/Section';
import Subsection from 'components/Subsection/Subsection';
import Title from 'components/Title/Title';
import DataList from 'components/DataList/DataList';
import Filter from 'components/Filter/Filter';

import s from './Products.module.css'
import data from '../../fresco_data.json'

function Products({children}) {   
  const isMobile = useMediaQuery({ query: '(max-width: 767px)'  })
  const isTablet = useMediaQuery({ query: '(min-width: 768px) and (max-width: 1399px)' })
  const isDesktop = useMediaQuery({ query: '(min-width: 1400px)' })

  const useLocalStorage = (key, defaultValue) => {
    const [state, setState] = useState(() => {
      return JSON.parse(window.localStorage.getItem(key)) ?? defaultValue;
    });

    useEffect(
      prevState => {
        if (state !== prevState) {
          window.localStorage.setItem(key, JSON.stringify(state));
        }
      },
      [key, state]
    );

    return [state, setState];
  };

  const [idMin,setIdMin] = useState(0);
  const [idMax,setIdMax] = useState(100000000000);

  const handleIdMin = e => {
    const { value } = e.target;
    setIdMin(value);
  };
  
  const handleIdMax = e => {
    const { value } = e.target;
    setIdMax(value);
  };

    return ( 
      <Section>
         <Title title="Data Access"/>  
        <Subsection>
        <p>
            Science grade, reduced images and spectra will be released here, as soon as they are available. 
            Please check back soon.
        </p>
        <p>
            The JWST raw data of our survey are public immediately, and can be accessed through the MAST archive.
        </p>
        <div className={s.par}>  
        The imaging data have been delivered to MAST and are being processed. They will be available via this link:        
        <a href="https://archive.stsci.edu/hlsp/fresco" target="_blank" rel="noreferrer noopener">   <Btn text="Mast data release"/> </a> 
        </div>
        <div className={s.par}>
          The [OIII] catalogs from 
          <a href="https://ui.adsabs.harvard.edu/abs/2024arXiv240505111M/abstract" target="_blank" rel="noreferrer noopener"> Meyer+24 </a> 
          are available here: 
        <a href="https://github.com/rameyer/fresco/" target="_blank" rel="noreferrer noopener">  <Btn text="[OIII] catalogs"/>  </a></div>
        <p>
            For more information, please contact us at: pascal.oesch[at]unige.ch
        </p>
        </Subsection>

      </Section>
    
    ) ;
  }
  
  export default Products;